<template>
  <div class="text-center">
    <q-spinner :color="color" :size="size" />
    <p v-if="text.length > 0" class="q-mt-sm text-gray-medium">
      <small>{{ text }}</small>
    </p>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    text: {
      type: String,
      required: false,
      default: ""
    },
    size: {
      type: String,
      required: false,
      default: "2.5em"
    },
    color: {
      type: String,
      required: false,
      default: "primary"
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>

/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  POST_REQUEST,
  ADD_PENDING_REQUEST,
  CLEAR_PENDING_REQUESTS
} from "@/store/actions/advertiserReporting";

const state = {
  pendingRequests: {}
};

const getters = {};

const actions = {
  [POST_REQUEST]: (
    { commit, dispatch, rootState },
    { params = {}, signalIdentifier = "" }
  ) => {
    return new Promise((resolve, reject) => {
      if (signalIdentifier.length > 0) {
        commit(CLEAR_PENDING_REQUESTS, {
          signalIdentifier: signalIdentifier
        });
      }

      let controller = new AbortController();
      let { signal } = controller;

      if (signalIdentifier.length > 0) {
        commit(ADD_PENDING_REQUEST, {
          signalIdentifier: signalIdentifier,
          controller: controller
        });
      }

      axios({
        url:
          process.env.VUE_APP_API_BASE_URL +
          "/network/app/advertiser/reporting",
        method: "POST",
        signal: signal,
        data: {
          timezone: rootState.business.record.timezone,
          ...params
        }
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [CLEAR_PENDING_REQUESTS]: (state, { signalIdentifier = "" }) => {
    if (
      signalIdentifier in state.pendingRequests &&
      state.pendingRequests[signalIdentifier].length > 0
    ) {
      state.pendingRequests[signalIdentifier].forEach(controller => {
        controller.abort();
      });
      state.pendingRequests[signalIdentifier] = [];
    }
  },
  [ADD_PENDING_REQUEST]: (
    state,
    { signalIdentifier = "", controller = null }
  ) => {
    state.pendingRequests[signalIdentifier] = [];
    state.pendingRequests[signalIdentifier].push(controller);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

/* eslint-disable no-unused-vars */
import axios from "axios";

import { GET_REQUEST, POST_REQUEST } from "@/store/actions/advertiserAccounts";
import mixins from "@/mixins";

const state = {};

const getters = {};

const actions = {
  [GET_REQUEST]: ({ commit, dispatch, rootState }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_BASE_URL + "/network/app/advertiser-accounts",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          let accounts = responseData.map(aa => {
            let status = "active";
            if (aa.active_inventory_sources == 0) status = "disabled";
            else if (aa.active == 0 || aa.paused == 1) status = "deactivated";
            else if (
              aa.active == 1 &&
              (aa.daily_budget_reached == 1 || aa.products_active_total == 0)
            )
              status = "warning";

            let statusTooltip = mixins.methods.capitalizeText(status);
            if (status === "disabled") statusTooltip = "Disabled - No Active Sources";
            else if (status === "deactivated")
              statusTooltip = "Stopped - Not Delivering";
            else if (status === "active") statusTooltip = "Active - Delivering";
            else if (status === "warning") {
              if (aa.daily_budget_reached == 1) {
                statusTooltip =
                  "Daily Budget Reached - Delivery will resume tomorrow.";
              } else if (aa.products_active_total == 0) {
                statusTooltip = "Delivery Unavailable - No Active " + rootState.productTypeConfigs.typeFormatted + "s";
              }
            }

            return { ...aa, status: status, status_tooltip: statusTooltip };
          });
          resolve(accounts);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POST_REQUEST]: ({ commit, dispatch }, { endpoint, params = {} }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/network/app/advertiser-accounts/" +
            endpoint,
          params
        )
        .then(resp => {
          // dispatch(POPULATE_LIST); // Re-populate the list to reflect changes.
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

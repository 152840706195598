/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  POST_REQUEST,
  GET_IMPORT_HISTORY_REQUEST
} from "@/store/actions/inventorySources";

const state = {};

const getters = {};

const actions = {
  [GET_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_BASE_URL + "/network/app/inventory-sources",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POST_REQUEST]: ({ commit, dispatch }, { endpoint, params = {} }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/network/app/inventory-sources/" +
            endpoint,
          params
        )
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [GET_IMPORT_HISTORY_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_BASE_URL +
          "/network/app/inventory-sources/import-history",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  POST_REQUEST,
  POPULATE_LIST,
  POPULATE_LIST_SUCCESS,
  SET_POPULATING_LIST_VALUE
} from "@/store/actions/publisherAccounts";

const state = {
  list: [],
  populatingList: false
};

const getters = {};

const actions = {
  [POPULATE_LIST]: ({ commit, dispatch }) => {
    commit(SET_POPULATING_LIST_VALUE, true);
    return new Promise((resolve, reject) => {
      dispatch(GET_REQUEST, {})
        .then(data => {
          commit(POPULATE_LIST_SUCCESS, data);
          commit(SET_POPULATING_LIST_VALUE, false);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.VUE_APP_API_BASE_URL + "/network/app/publisher-accounts",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POST_REQUEST]: ({ commit, dispatch }, { endpoint, params = {} }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/network/app/publisher-accounts/" +
            endpoint,
          params
        )
        .then(resp => {
          dispatch(POPULATE_LIST); // Re-populate the list to reflect changes.
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_LIST_SUCCESS]: (state, data) => {
    state.list = data;
  },
  [SET_POPULATING_LIST_VALUE]: (state, value) => {
    state.populatingList = !!value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import users from "@/store/modules/users";
import business from "@/store/modules/business";
import advertiserAccounts from "@/store/modules/advertiserAccounts";
import publisherAccounts from "@/store/modules/publisherAccounts";
import inventorySources from "@/store/modules/inventorySources";
import advertiserReporting from "@/store/modules/advertiserReporting";

import carscienceLogoLightImage from "@/assets/images/logos/carscience-beta-logo-light.svg";
import carscienceLogoDarkImage from "@/assets/images/logos/carscience-beta-logo-dark.svg";

export default createStore({
  state: {
    branding: {
      name: "CarScience",
      logoLight: carscienceLogoLightImage,
      logoDark: carscienceLogoDarkImage,
      domain: "carscience.com",
    },
    productTypeConfigs: {
      id: 1,
      type: "vehicle",
      typeFormatted: "Vehicle",
      identifierField: "vehicle_vin",
      nameField: "product_title"
    },
    workspace: localStorage.getItem("app-lastWorkspace") ?? "",
    appLoader: false,
    leftDrawerOpen: true,
    leftDrawerMini: true
  },
  getters: {
    workspace: state => state.workspace,
    productType: state => (formatted = false, plural = false) => {
      if (state.productTypeId == 1) {
        return (formatted ? "Vehicle" : "vehicle") + (plural ? 's' : '');
      }
      return "";
    }
  },
  mutations: {
    toggleLeftDrawerOpen(state) {
      state.leftDrawerOpen = !state.leftDrawerOpen;
    },
    toggleLeftDrawerMini(state) {
      state.leftDrawerMini = !state.leftDrawerMini;
    },
    setLeftDrawerMini(state, value) {
      state.leftDrawerMini = value;
    },
    updateWorkspace(state, workspace) {
      localStorage.setItem("app-lastWorkspace", workspace);
      state.workspace = workspace;
    },
    setAppLoader(state, value) {
      state.appLoader = value;
    }
  },
  actions: {
    reset({ commit }) {
      commit("user/RESET");
    }
  },
  modules: {
    auth,
    user,
    users,
    business,
    advertiserAccounts,
    advertiserReporting,
    publisherAccounts,
    inventorySources
  }
});

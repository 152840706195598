/* eslint-disable no-unused-vars */
import axios from "axios";

import { REQUEST, SUCCESS, ERROR, LOGIN, LOGOUT } from "@/store/actions/auth";

const state = {
  token: localStorage.getItem("app-jwt") || ""
};

const getters = {
  isAuthenticated: state => !!state.token
};

const actions = {
  [REQUEST]: ({ commit, dispatch }, userData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_BASE_URL + "/network/app/auth",
        data: userData,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(resp => {
          const token = resp.data.data.token;
          commit(SUCCESS, token);
        })
        .then(resp => dispatch(LOGIN))
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          commit(ERROR);
          dispatch(LOGOUT).then(() => {
            reject(err);
          });
        });
    });
  },
  [LOGIN]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      let token = state.token;
      window.localStorage.setItem("app-jwt", token); // store the user's token in localstorage
      axios.defaults.headers.common["Authorization"] = "Bearer " + token; // add the token as a auth header for all requests, so all API calls are authenticated.

      // resolve();

      // now that we have a token -> actually login the user by performing appropriate login actions, and then resolve our promise
      let signInActions = [
        dispatch("user/POPULATE_RECORD", null, { root: true }),
        dispatch("business/POPULATE_RECORD", null, { root: true })
      ];

      Promise.all(signInActions)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(LOGOUT);
      window.localStorage.removeItem("app-jwt"); // remove the token from localstorage
      delete axios.defaults.headers.common["Authorization"]; // remove the token from the auth header for all requests
      resolve();
    });
  }
};

const mutations = {
  [SUCCESS]: (state, token) => {
    state.token = token;
  },
  [ERROR]: state => {
    state.token = "";
  },
  [LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

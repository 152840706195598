import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import mixins from "./mixins";

const app = createApp(App);

app.config.globalProperties.$_ = require("lodash");

app.config.globalProperties.$dayjs = require("dayjs");
const dayjsUtc = require("dayjs/plugin/utc");
app.config.globalProperties.$dayjs.extend(dayjsUtc);
const dayjsRelativeTime = require("dayjs/plugin/relativeTime");
app.config.globalProperties.$dayjs.extend(dayjsRelativeTime, {
  thresholds: [
    { l: "s", r: 1 },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 29, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y", r: 1 },
    { l: "yy", d: "year" }
  ],
  rounding: Math.round
});
const dayjsCustomParseFormat = require("dayjs/plugin/customParseFormat");
app.config.globalProperties.$dayjs.extend(dayjsCustomParseFormat);
const dayjsLocalizedFormat = require("dayjs/plugin/localizedFormat");
app.config.globalProperties.$dayjs.extend(dayjsLocalizedFormat);
const dayjsTimezone = require("dayjs/plugin/timezone");
app.config.globalProperties.$dayjs.extend(dayjsTimezone);

app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(router);
app.mixin(mixins);

app.directive("hover-editable", {
  beforeMount(el) {
    let icon = null;

    el.style.cursor = "pointer";
    el.style.position = "relative";

    let hoverFunction = () => {
      //el.classList.add('--editable-hover');
      icon = document.createElement("span");
      icon.className = "material-icons";
      icon.innerText = "edit";
      icon.style.position = "absolute";
      icon.style.top = "50%";
      icon.style.transform = "translateY(-50%)";
      icon.style.right = "5%";
      icon.style.color = "#616161";
      el.append(icon);
    };
    let unhoverFunction = () => {
      //el.classList.remove('--editable-hover');
      el.removeChild(icon);
    };
    el.addEventListener("mouseover", hoverFunction);
    el.addEventListener("mouseout", unhoverFunction);
  }
});

/* app.directive("draggable", {
  mounted(el) {
    let icon = null;

    el.style.cursor = "move";
    el.style.cursor = "grab";
    el.style.cursor = "-moz-grab";
    el.style.cursor = "-webkit-grab";
    el.style.position = "relative";

    icon = document.createElement("span");
    icon.className = "material-symbols-rounded";
    icon.innerText = "drag_pan";
    icon.style.position = "absolute";
    icon.style.top = "8px";
    icon.style.right = "8px";
    icon.style.color = "#616161";
    el.append(icon);
  }
}); */

app.mount("#app");

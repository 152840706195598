/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/sign-in",
    component: () => import("@/components/Skeletons/ExternalFacingSkeleton"),
    children: [
      {
        path: "/sign-up",
        name: "SignUp",
        /*meta: {
          title: "Advertiser Sign Up"
        },*/
        component: () => import("@/views/SignUp"),
      },
      {
        path: "/sign-in",
        name: "SignIn",
        component: () => import("@/views/SignIn"),
        /*meta: {
          title: "Advertiser Sign In"
        }*/
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/ForgotPassword"),
        /*meta: {
          title: "Advertiser Sign In"
        }*/
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("@/views/CreateResetPassword"),
        /*meta: {
          title: "Advertiser Sign In"
        },*/
        props: () => ({
          mode: "reset"
        })
      },
      {
        path: "/create-password",
        name: "CreatePassword",
        component: () => import("@/views/CreateResetPassword"),
        props: () => ({
          mode: "create"
        })
      }
    ]
  },
  /*{
    path: "/",
    name: "root",
    redirect: "/advertiser/overview"
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import("@/views/SignUp")
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn")
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword")
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/CreateResetPassword"),
    props: () => ({
      mode: "reset"
    })
  },
  {
    path: "/create-password",
    name: "CreatePassword",
    component: () => import("@/views/CreateResetPassword"),
    props: () => ({
      mode: "create"
    })
  },*/
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      store.dispatch("auth/LOGOUT").then(() => {
        store.dispatch("reset").then(() => {
          return next("/sign-in");
        });
      });
    }
  },
  {
    path: "/advertiser/",
    name: "",
    redirect: "/advertiser/overview",
    component: () => import("@/components/Skeletons/AdvertiserDashboard"),
    children: [
      {
        path: ":pathMatch(.*)*",
        name: "Advertiser404",
        component: () => import("@/views/NotFound.vue"),
        props: {
          homeDestination: "/advertiser/overview"
        }
      },
      {
        path: "overview",
        name: "AdvertiserOverview",
        component: () => import("@/views/Advertiser/AdvertiserOverview")
      },
      {
        path: "admin/business-settings",
        name: "AdvertiserBusinessSettings",
        component: () =>
          import("@/views/Advertiser/Admin/AdvertiserBusinessSettings")
      },
      {
        path: "admin/user-access",
        name: "AdvertiserUserAccessOverview",
        component: () =>
          import("@/views/Advertiser/Admin/AdvertiserUserAccessOverview")
      },
      {
        path: "admin/user/create",
        name: "AdvertiserUserAccountCreator",
        component: () =>
          import("@/views/Advertiser/Admin/AdvertiserUserAccountCreator")
      },
      {
        path: "admin/user/:userId/edit",
        name: "AdvertiserUserAccountEditor",
        component: () =>
          import("@/views/Advertiser/Admin/AdvertiserUserAccountEditor"),
        props: route => ({
          userId: route.params.userId
        }),
        beforeEnter: (to, from, next) => {
          if (!to.params.userId) {
            return next("/admin/user-access");
          }
          return next();
        }
      },
      {
        path: "reporting",
        name: "ReportingOverview",
        component: () =>
          import("@/views/Advertiser/Reporting/ReportingOverview")
      },
      /*{
        path: "accounts",
        name: "AdvertiserAccountsOverview",
        component: () =>
          import("@/views/Advertiser/Accounts/AdvertiserAccountsOverview")
      },*/
      {
        path: "accounts",
        name: "AdvertiserAccountsOverview",
        component: () =>
          import("@/views/Advertiser/Accounts/AdvertiserAccountsOverview.vue")
      },
      {
        path: "accounts/create",
        name: "AdvertiserAccountCreator",
        component: () =>
          import("@/views/Advertiser/Accounts/AdvertiserAccountCreator")
      },
      {
        path: "accounts/:advertiserAccountId/edit",
        name: "AdvertiserAccountEditor",
        component: () =>
          import("@/views/Advertiser/Accounts/AdvertiserAccountEditor"),
        props: route => ({
          advertiserAccountId: route.params.advertiserAccountId
        }),
        beforeEnter: (to, from, next) => {
          if (
            !to.params.advertiserAccountId ||
            !store.state.user.record.advertiser_accounts.includes(
              to.params.advertiserAccountId
            )
          ) {
            return next("/advertiser/accounts");
          }
          return next();
        }
      },
      /*{
        path: "accounts/:advertiserAccountId/inventory-sources",
        name: "InventorySourcesOverview",
        component: () =>
          import(
            "@/views/Advertiser/InventorySources/InventorySourcesOverview"
          ),
        props: route => ({
          advertiserAccountId: route.params.advertiserAccountId
        }),
        beforeEnter: (to, from, next) => {
          if (
            !to.params.advertiserAccountId ||
            !store.state.user.record.advertiser_accounts.includes(
              to.params.advertiserAccountId
            )
          ) {
            return next("/advertiser/accounts");
          }
          return next();
        }
      },*/
      {
        path: "accounts/inventory-sources/create",
        name: "InventorySourceCreator",
        component: () =>
          import("@/views/Advertiser/InventorySources/InventorySourceCreator")
      },
      {
        path:
          "accounts/:advertiserAccountId/inventory-sources/:inventorySourceId/edit",
        name: "InventorySourceEditor",
        component: () =>
          import("@/views/Advertiser/InventorySources/InventorySourcesEditor"),
        props: route => ({
          advertiserAccountId: route.params.advertiserAccountId,
          inventorySourceId: route.params.inventorySourceId
        }),
        beforeEnter: (to, from, next) => {
          if (
            !to.params.advertiserAccountId ||
            !to.params.inventorySourceId ||
            !store.state.user.record.advertiser_accounts.includes(
              to.params.advertiserAccountId
            )
          ) {
            return next("/advertiser/accounts");
          }
          return next();
        }
      },
      {
        path:
          "accounts/:advertiserAccountId/inventory-sources/:inventorySourceId/import-history",
        name: "InventorySourceImportHistory",
        component: () =>
          import(
            "@/views/Advertiser/InventorySources/InventorySourceImportHistory.vue"
          ),
        props: route => ({
          advertiserAccountId: route.params.advertiserAccountId,
          inventorySourceId: route.params.inventorySourceId
        }),
        beforeEnter: (to, from, next) => {
          if (
            !to.params.advertiserAccountId ||
            !to.params.inventorySourceId ||
            !store.state.user.record.advertiser_accounts.includes(
              to.params.advertiserAccountId
            )
          ) {
            return next("/advertiser/accounts");
          }
          return next();
        }
      },
      {
        path: ":productType",
        name: "ProductListings",
        component: () =>
          import("@/views/Advertiser/Products/ProductListings.vue"),
        props: route => ({
          productType: route.params.productType
        }),
      }
    ]
  },
  {
    path: "/publisher/",
    name: "",
    redirect: "/publisher/overview",
    component: () => import("@/components/Skeletons/PublisherDashboard"),
    children: [
      {
        path: ":pathMatch(.*)*",
        name: "Publisher404",
        component: () => import("@/views/NotFound.vue"),
        props: {
          homeDestination: "/publisher/overview"
        }
      }
    ]
  }
];

// TODO: Account for below on the server.
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // console.log(1);

  // If authenticated
  if (store.getters["auth/isAuthenticated"]) {
    // console.log(2);

    // Confirm we have all the needed headers/initial store data for the authenticated user.
    let validateAuthenticationData = new Promise((resolve, reject) => {
      // If user is authenticated but we are missing needed data, than show the app loader while we re-perform necessary initial auth.
      if (
        typeof axios.defaults.headers.common["Authorization"] === "undefined" ||
        !store.getters["business/isRecordLoaded"] ||
        !store.getters["user/isRecordLoaded"]
      ) {
        //
        store.commit("setAppLoader", true);
        store
          .dispatch("auth/LOGIN")
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      } else {
        resolve();
      }
    });

    // Allow user through on success, else send back to sign in
    validateAuthenticationData
      .then(() => {
        // console.log(3);

        if (
          [
            "SignIn",
            "ForgotPassword",
            "ResetPassword",
            "CreatePassword",
            "SignUp"
          ].includes(to.name)
        ) {
          // console.log(4);

          return next("/advertiser/");
        }

        if (
          to.path !== "/logout" &&
          store.state.business.record.advertiser_enabled == 0 &&
          store.state.business.record.publisher_enabled == 0
        ) {
          // console.log(41);
          return next("/logout");
        }

        // Validate access to the workspace and/or update the workspace based on route path
        if (to.matched.some(route => route.path.includes("/advertiser"))) {
          if (store.state.business.record.advertiser_enabled == 0) {
            // console.log(5);

            return next("/publisher/");
          } else {
            store.commit("updateWorkspace", "Advertiser");

            // if business needs initial creation, send to accounts for new account creation instead.
            if (
              store.state.business.record.advertiser_initial_flow_completed ==
                0 &&
              !to.matched.some(
                route =>
                  route.path.includes("/advertiser/accounts") ||
                  route.path.includes("/advertiser/admin")
              )
            ) {
              // console.log(6);

              return next("/advertiser/accounts");
            }
          }
        } else if (
          to.matched.some(route => route.path.includes("/publisher"))
        ) {
          if (store.state.business.record.publisher_enabled == 0) {
            // console.log(7);

            return next("/advertiser/");
          } else {
            store.commit("updateWorkspace", "Publisher");
          }
        }

        // If "to" has no matches, then redirect to root
        /*if (to.matched.length === 0) {
          return next("/");
        }*/

        // console.log(8);

        return next();
      })
      .catch(() => {
        // console.log(9);

        return next({ name: "SignIn" });
      });
  } else {
    // If not authenticated
    if (
      !["SignIn", "SignUp", "ForgotPassword", "ResetPassword", "CreatePassword"].includes(
        to.name
      )
    ) {
      // console.log(10);

      return next({ name: "SignIn" });
    } else {
      // console.log(11);

      return next();
    }
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    store.commit("setAppLoader", false);
  }, 300);
});

export default router;

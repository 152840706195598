/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  POPULATE_RECORD,
  POST_REQUEST,
  POPULATE_RECORD_ERROR,
  POPULATE_RECORD_SUCCESS
} from "@/store/actions/business";

const state = { record: {} };

const getters = {
  isRecordLoaded: state => !!state.record.id,
  advertiserInitialFlowRestricted: state =>
    state.record.advertiser_initial_flow_completed == 0
};

const actions = {
  [GET_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_BASE_URL + "/network/app/business" })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POPULATE_RECORD]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_BASE_URL + "/network/app/business" })
        .then(resp => {
          commit(POPULATE_RECORD_SUCCESS, resp.data);
          resolve();
        })
        .catch(err => {
          console.log(err);
          commit(POPULATE_RECORD_ERROR);
          dispatch("auth/LOGOUT", null, { root: true }); // if cannot get business data, logout too
          reject(err);
        });
    });
  },
  [POST_REQUEST]: ({ commit, dispatch }, { endpoint, params = {} }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "/network/app/business/" +
            endpoint,
          params
        )
        .then(resp => {
          dispatch(POPULATE_RECORD); // Re-populate the list to reflect changes.
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_RECORD_SUCCESS]: (state, resp) => {
    state.record = resp.data;
  },
  [POPULATE_RECORD_ERROR]: state => {
    state.record = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  POPULATE_RECORD,
  POPULATE_RECORD_ERROR,
  POPULATE_RECORD_SUCCESS,
  RESET
} from "@/store/actions/user";

const state = { record: {} };

const getters = {
  isRecordLoaded: state => !!state.record.id,
  displayName: state =>
    (state.record.first_name ?? "") + " " + (state.record.last_name ?? "")
};

const actions = {
  [GET_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_BASE_URL + "/network/app/user" })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POPULATE_RECORD]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({ url: process.env.VUE_APP_API_BASE_URL + "/network/app/user" })
        .then(resp => {
          commit(POPULATE_RECORD_SUCCESS, resp.data);
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          commit(POPULATE_RECORD_ERROR);
          dispatch("auth/LOGOUT", null, { root: true }); // if resp is unauthorized, logout too
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_RECORD_SUCCESS]: (state, resp) => {
    state.record = resp.data;
  },
  [POPULATE_RECORD_ERROR]: state => {
    state.record = {};
  },
  [RESET]: state => {
    state.record = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <router-view v-slot="{ Component, route }">
    <transition appear :name="route.meta.transition || 'fade'" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <template v-if="$store.state.appLoader">
    <div class="column items-center justify-center fullscreen bg-white z-max">
      <Loader size="3.25em" />
      <div class="full-width text-center q-mt-lg">
        <img
          :src="$store.state.branding.logoDark"
          style="width: 155px; align-self: flex-start;"
        />
      </div>
    </div>
  </template>
</template>

<script>
import Loader from "@/components/UI/Loader";

export default {
  components: {
    Loader
  },
  mounted() {
    this.$dayjs.tz.setDefault(this.$store.state.business.record.timezone);
  }
};
</script>

<style lang="scss">
#app {
  background: color(background);
}
</style>

// import "./styles/vendor/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-symbols-rounded/material-symbols-rounded.css";
import iconSet from "quasar/icon-set/material-symbols-rounded";
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  iconSet: iconSet,
  plugins: {
    Dialog,
    Notify
  }
};
